import swaggerClient from "../plugins/swagger-client";

export default class ProjectService {
  retrieveProjectsByClientId(clientId, limit = 0, skip = 0, projectsStatus = '',sort='dec',projectsInfolder='exclude') {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.retrieveProjects(
          { clientId, limit, skip, projectsStatus,sort,projectsInfolder },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  searchProjectByName(clientId, limit = 0, search = '') {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.searchProjects(
          { clientId, limit, search },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      })
  }

  retrieveProjectByClientId(clientId, projectId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.retrieveProject(
          { clientId, projectId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  createProjectByClientId(clientId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.createProject(
          { clientId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateProjectByClientId(clientId, projectId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.updateProject(
          { clientId, projectId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteProjectByClientId(clientId, projectId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.deleteProject(
          { clientId, projectId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  buyProject(clientId, projectId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.buyProject(
          { clientId, projectId },
          swaggerClient.buildRequestBody({}),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  setProjectThumbnail(clientId, projectId, thumbnail) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.setProjectThumbnail(
          { clientId, projectId },
          swaggerClient.buildRequestBody({
            file: thumbnail
          }),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  createProjectAsset(projectId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.createProjectAsset(
          { projectId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveProjectAssets(projectId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.retrieveProjectAssets(
          { projectId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateProjectAsset(projectId, assetId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.updateProjectAsset(
          { projectId, assetId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteProjectAsset(projectId, assetId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.deleteProjectAsset(
          { projectId, assetId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveCountries() {
    return Promise.resolve([])
    // return swaggerClient.swagger
    //   .then(client => {
    //     return client.apis.utils.retrieveCountriesV11(
    //       {},
    //       swaggerClient.buildRequestBody(),
    //       {}
    //     );
    //   })
    //   .then(response => {
    //     return response.body;
    //   })
    //   .catch(error => {
    //     return Promise.reject(error);
    //   });
  }

  retrieveProjectFeasibility(clientId, projectId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.retrieveProjectFeasibility(
          { clientId, projectId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
