<template>
  <div id="app" class="bg-light min-vh-100">
    <router-view />

    <base-loader-dialog />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "assets/custom-bootstrap-vars.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";
</style>

<style scoped>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
</style>

<style>
* {
  font-family: Roboto !important;
  --emotiva-pink:#6200ee;
  --emotiva-pink-login:#64408f;
  --emotiva-pink-light:#BB86FC;
  --emotiva-pink-very-light:#F2E7FE;
  --emotiva-white-text:#717D96
}
.link {
  text-decoration: underline;
}
.button--dark-border {
  border: 1px solid #ced4da;
}
.text__xs {
  font-size: 12px;
}
.bg-blue-primary {
  background-color: #033051;
}

.title__1 {
  /* font-family: ; */
  font-size: 1.2rem;
  font-weight: 700;
}
.title__2 {
  /* font-family: ; */
  font-size: 1.2rem;
  font-weight: 400;
}
.title__3 {
  /* font-family: ; */
  font-size: 0.8rem;
  font-weight: 700;
}
.subtitle__1 {
  /* font-family: ; */
  font-size: 0.8rem;
  font-weight: 400;
}
.body__2 {
  font-size: 14px;
  font-weight: 400;
}
.clickable {
  cursor: pointer;
}
.btn-secondary {
  background: #dddddd;
  border: unset;
  color: #6c757d;
  font-weight: 400;
}
.btn-outline-gray-dark,
.btn-outline-light {
  border-color: #c2c2c2;
}
.btn-outline-gray-medium {
  border-color: rgb(219, 219, 219);
  color: rgb(219, 219, 219);
}
.btn-outline-gray-medium:hover,
.btn-outline-gray-medium.active {
  color: white !important;
}

.p_t__3 {
  padding-top: 100px;
}
.p_b__3 {
  padding-bottom: 100px;
}
.p_y__3 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.bg-black {
  background-color: black;
}

.emotiva-pink-background {
  background-color: #6200ee;
}
.emotiva-pink-background-light{
  background-color: #F2E7FE;
}

.no-decoration-btn {
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
  text-decoration: none;
}
.no-decoration-btn:hover,
.no-decoration-btn:focus,
.no-decoration-btn:active {
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
  text-decoration: none;
  color: inherit; /* Ensures the text color doesn't change on hover */
  outline: none; /* Removes the outline on focus */
}
.modal-content{
  border-radius: 20px;
  padding: 25px;
}
</style>
