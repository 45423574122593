import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

import Utils from "../Utils";
import i18n from "../i18n";
import store from "../store";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: "/",
    beforeEnter(to, from, next) {
      next({ name: "PagesContainer" });
    },
  },
  {
    path: "/:lang",
    name: "PagesContainer",
    component: () => import("../views/Workspace.vue"),
    redirect: { name: "Dashboard" },
    beforeEnter(to, from, next) {
      const supportedLocaleList = i18n.availableLocales;
      if (!to.params.lang) {
        const params = { ...to.params, lang: i18n.locale };
        router.replace({ name: to.name, params });
      } else {
        if (!supportedLocaleList.includes(to.params.lang)) {
          to.params.lang = i18n.locale;
          router.replace({ name: to.name, params: to.params });
        } else {
          i18n.locale = to.params.lang;
          next({ params: to.params });
        }
      }
    },
    children: [
      {
        path: "share/project/:projectId/:clientId/:sharingToken",
        name: "shareAnalytics",
        redirect: { name: "shareRanking" },
        component: () => import("../views/Results.vue"),
        beforeEnter(to, from, next) {
          store.commit("analysis/SET_GUEST_MODE", true);
          store.commit("analysis/SET_TAB", 0);
          store.commit("analysis/SET_CURRENT_VIDEO_INDEX", 0);
          store
            .dispatch("analysis/retrieveVideoDataBySharingToken", {
              sharingToken: to.params.sharingToken,
              projectId: to.params.projectId,
              clientId: to.params.clientId,
            })
            .then(() => {
              return store.dispatch("analysis/retrieveNorms");
            })
            .then(() => next())
            .catch((error) => {
              console.log(error);
              store.commit("error/SET_ERROR", {
                messageText: "dialog.error.pageNotFoundMessage",
              });
              next({ name: "Dashboard", params: { is404: true } });
            });
        },
        children: [
          {
            path: "ranking",
            name: "shareRanking",
            component: () => import("../views/Ranking.vue"),
          },
          {
            path: "metrics",
            name: "shareScore",
            component: () => import("../views/Score.vue"),
            beforeEnter(to, from, next) {
              const currentVideoIndex =
                store.getters["analysis/getCurrentVideoIndex"];
              const videoDataLength =
                store.getters["analysis/getVideoDataLength"];

              if (currentVideoIndex > videoDataLength - 1 || currentVideoIndex < 0)
                store.commit("analysis/SET_CURRENT_VIDEO_INDEX", 0);

              next();
            },
          },
          {
            path: "chart",
            name: "shareGraph",
            component: () => import("../views/Graph.vue"),
            beforeEnter(to, from, next) {
              const currentVideoIndex =
                store.getters["analysis/getCurrentVideoIndex"];
              const videoDataLength =
                store.getters["analysis/getVideoDataLength"];

              if (currentVideoIndex > videoDataLength - 1 || currentVideoIndex < 0)
                store.commit("analysis/SET_CURRENT_VIDEO_INDEX", 0);

              next();
            },
          },
          {
            path: "metrics-in-context",
            name: "shareInContextScore",
            component: () => import("../views/InContextScore.vue"),
            beforeEnter(to, from, next) {
              const currentVideoIndex =
                store.getters["analysis/getCurrentVideoIndex"];
              const videoDataLength =
                store.getters["analysis/getVideoDataLength"];

              if (currentVideoIndex > videoDataLength - 1 || currentVideoIndex < 0)
                store.commit("analysis/SET_CURRENT_VIDEO_INDEX", 0);

              next();
            },
          },
        ],
      },
      {
        path: 'project/:id/analytics/ranking',
        name: 'analyticsEmailLink', // link as it appears on the email
        beforeEnter(to, from, next) {
          const projectId = to.params.id;
          next({ name: 'analytics', params: { id: projectId } });
        }
      },
      {
        path: "project/:id/project-analysis",
        name: "project-analysis",
        component: () => import("../views/ProjectAnalysis.vue"),

        beforeEnter(to, from, next) {
          const payload = {
            projectId: to.params.id,
            isComparisson: false,
          }
          store.commit("analysisV2/SET_GUEST_MODE", false);
          store
            .dispatch("analysisV2/retrieveAnalysisData", payload)
            .then((response) => {
              store.commit("project/SET_ACTIVE_PROJECT", response.project)
            })
            .then(() => {
              next();
            })
            .catch((error) => {
              store.dispatch("loader/close");
              console.log(error);
            });
        },
        children: [
          {
            path: "heatmap",
            name: "heatmap",
            component: () => import("../views/Heatmap.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "comparisson/:comparissonProjectId",
            name: "Comparisson",
            component: () => import("../views/HeatmapComparisson.vue"),
            beforeEnter(to, from, next) {
              const payload = {
                projectId: to.params.comparissonProjectId,
                isComparisson: true,
              }
              store.commit("analysisV2/SET_GUEST_MODE", false);
              store
                .dispatch("analysisV2/retrieveAnalysisData", payload)
                .then((response) => {
                  store.commit("project/SET_PROJECT_TO_COMPARE", response.project)
                })
                .then(() => {
                  next();
                })
                .catch((error) => {
                  store.dispatch("loader/close");
                  console.log(error);
                });
            },
            meta: {
              requiresAuth: true,
            },

          },
        ]
      },
      {
        path: "project/:id",
        name: "analytics",
        component: () => import("../views/Results.vue"),
        redirect: { name: "ranking" },
        beforeEnter(to, from, next) {
          store.commit("analysis/SET_GUEST_MODE", false);
          store.commit("analysis/SET_TAB", 0);
          store.commit("analysis/SET_CURRENT_VIDEO_INDEX", 0);
          store
            .dispatch("analysis/retrieveAnalysisData", {
              projectId: to.params.id,
              clientId: store.getters["auth/getClientId"],
            })
            .then(() => {
              return store.dispatch("analysis/retrieveNorms");
            })
            .then(() => {
              next();
            })
            .catch((error) => {
              store.dispatch("loader/close");
              console.log(error);
            });
        },
        children: [
          {
            path: "ranking",
            name: "ranking",
            component: () => import("../views/Ranking.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "metrics",
            name: "score",
            component: () => import("../views/Score.vue"),
            meta: {
              requiresAuth: true,
            },
            beforeEnter(to, from, next) {
              // store.commit("analysis/SET_CURRENT_VIDEO_INDEX", 0);
              const currentVideoIndex =
                store.getters["analysis/getCurrentVideoIndex"];
              const videoDataLength =
                store.getters["analysis/getVideoDataLength"];
              if (currentVideoIndex > videoDataLength - 1 || currentVideoIndex < 0)
                store.commit("analysis/SET_CURRENT_VIDEO_INDEX", 0);

              next();
            },
          },
          {
            path: "chart",
            name: "graph",
            component: () => import("../views/Graph.vue"),
            meta: {
              requiresAuth: true,
            },
            beforeEnter(to, from, next) {
              const currentVideoIndex =
                store.getters["analysis/getCurrentVideoIndex"];
              const videoDataLength =
                store.getters["analysis/getVideoDataLength"];

              if (currentVideoIndex > videoDataLength - 1 || currentVideoIndex < 0)
                store.commit("analysis/SET_CURRENT_VIDEO_INDEX", 0);

              next();
            },
          },
          {
            path: "metrics-in-context",
            name: "inContextScore",
            component: () => import("../views/InContextScore.vue"),
            meta: {
              requiresAuth: true,
            },
            beforeEnter(to, from, next) {
              // store.commit("analysis/SET_CURRENT_VIDEO_INDEX", 0);
              const currentVideoIndex =
                store.getters["analysis/getCurrentVideoIndex"];
              const videoDataLength =
                store.getters["analysis/getVideoDataLength"];
              if (currentVideoIndex > videoDataLength - 1 || currentVideoIndex < 0)
                store.commit("analysis/SET_CURRENT_VIDEO_INDEX", 0);

              next();
            },
          },
        ],
      },
      {
        path: "login",
        name: "Login",
        meta: {
          info: {
            title: "",
          },
        },
        component: () => import("../views/Login.vue"),
      },
      {
        path: "confirm/:token",
        name: "Confirm",
        component: () => import("../views/ConfirmAccount.vue"),
      },
      {
        path: "recovery/:token",
        name: "Recovery",
        component: () => import("../views/Recovery.vue"),
      },
      {
        path: "password-recovery",
        name: "PasswordRecovery",
        component: () => import("../views/PasswordRecovery.vue"),
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("../views/Dashboard.vue"),
        meta: {
          requiresAuth: true,
        },
        beforeEnter(to, from, next) {
          const projectList = store.getters["project/getProjectList"];
          store.commit("project/SET_MAX_CLOSED_PROJECTS_REACHED", false)
          store.commit("folder/SET_ACTIVE_FOLDER", {});
          store.commit("folder/SET_PROJECT_LIST", []);
          if (projectList && projectList.length > 0) {
            next();
            return
          }
          store
            .dispatch("project/retrieveProjectsByClientId", { limit: 30, projectsStatus: 'closed' })
            .then(() => {
              store.dispatch("project/retrieveProjectsByClientId", { limit: 10, projectsStatus: 'active', appendToProjects: true,projectsInfolder: 'any' })
            })
            .then(() => {
              return store.dispatch("folder/retrieveFoldersByClientId")
            })
            .then(() => {
              next();
            })
            .catch((isAuthorizationError) => {
              if (isAuthorizationError) store.dispatch("loader/close");
              else next();
            });
        },
      },
      // {
      //   path: "home",
      //   name:"home",
      //   component: ()=>import('../views/HomepageV2.vue'),
      //   beforeEnter(to, from, next) {
      //     store
      //       .dispatch("project/retrieveProjectsByClientId")
      //       .then(() => {
      //         return store.dispatch("folder/retrieveFoldersByClientId")
      //       })
      //       .then(() => {
      //         next();
      //       })
      //       .catch((isAuthorizationError) => {
      //         if (isAuthorizationError) store.dispatch("loader/close");
      //         else next();
      //       });
      //   },
      // },
      {
        path: 'folder/:id',
        name: "Folder",
        component: () => import("../views/Dashboard.vue"),
        meta: {
          requiresAuth: true
        },
        beforeEnter(to, from, next) {
          const folderId = to.params.id
          store
            .dispatch("folder/retrieveFolderByClientId", folderId)
            .then(() => {
              next();
            })
            .catch((isAuthorizationError) => {
              if (isAuthorizationError) store.dispatch("loader/close");
              else next();
            });
        },
      },
      {
        path: "account",
        name: "Account",
        component: () => import("../views/Account.vue"),
        meta: {
          requiresAuth: true,
        },
        beforeEnter(to, from, next) {
          if (isAllowedAccountRoute()) {
            store
              .dispatch("auth/retrieveClient")
              .then(() => {
                next();
              })
              .catch(() => store.dispatch("loader/close"));
          } else {
            store.commit("error/SET_ERROR", {
              messageText: "dialog.error.pageNotFoundMessage",
            });
            next({ name: "Dashboard", params: { is404: true } });
          }
        },
      },
      {
        path: "billing-info",
        name: "Billing-info",
        component: () => import("../views/BillingInfo.vue"),
        meta: {
          requiresAuth: true,
        },
        beforeEnter(to, from, next) {
          if (isAllowedAccountRoute()) {
            store.dispatch("auth/retrieveClient")
              .then(() => {
                next();
              })
          } else {
            store.commit("error/SET_ERROR", {
              messageText: "dialog.error.pageNotFoundMessage",
            });
            next({ name: "Dashboard", params: { is404: true } });
          }
        }
      },
      {
        path: "plan",
        name: "Plan",
        component: () => import("../views/Plan.vue"),
        meta: {
          requiresAuth: true,
        },
        beforeEnter(to, from, next) {
          if (isAllowedAccountRoute()) {
            store.dispatch("auth/retrieveClient")
              .then(() => {
                next();
              })
          } else {
            store.commit("error/SET_ERROR", {
              messageText: "dialog.error.pageNotFoundMessage",
            });
            next({ name: "Dashboard", params: { is404: true } });
          }
        }
      },
      {
        path: "branches",
        name: "Branches",
        component: () => import("../views/Branches.vue"),
        meta: {
          requiresAuth: true,
        },
        beforeEnter(to, from, next) {
          if (isAllowedBranchesRoute()) {
            store
              .dispatch("multiuser/retrieveClientUsers")
              .then(() => {
                next();
              })
              .catch(() => store.dispatch("loader/close"));
          } else {
            store.commit("error/SET_ERROR", {
              messageText: "dialog.error.pageNotFoundMessage",
            });
            next({ name: "Dashboard", params: { is404: true } });
          }
        },
      },
      {
        path: "users",
        name: "Users",
        component: () => import("../views/Users.vue"),
        meta: {
          requiresAuth: true,
        },
        beforeEnter(to, from, next) {
          if (isAllowedUsersRoute()) {
            store
              .dispatch("multiuser/retrieveClientUsers")
              .then(() => {
                next();
              })
              .catch(() => store.dispatch("loader/close"));
          } else {
            store.commit("error/SET_ERROR", {
              messageText: "dialog.error.pageNotFoundMessage",
            });
            next({ name: "Dashboard", params: { is404: true } });
          }
        },
      },
      {
        path: "billing",
        name: "Billing",
        component: () => import("../views/Billing.vue"),
        meta: {
          requiresAuth: true,
        },
        beforeEnter(to, from, next) {
          if (isAllowedBillingRoute()) {
            store
              .dispatch("billing/retrieveInvoices")
              .then(() => {
                next();
              })
              .catch(() => store.dispatch("loader/close"));
          } else {
            store.commit("error/SET_ERROR", {
              messageText: "dialog.error.pageNotFoundMessage",
            });
            next({ name: "Dashboard", params: { is404: true } });
          }
        },
      },
      {
        path: "usage",
        name: "Usage",
        component: () => import("../views/Usage.vue"),
        meta: {
          requiresAuth: true,
        },
        beforeEnter(to, from, next) {
          if (isAllowedUsageRoute()) {
            store
              .dispatch("auth/retrieveClient")
              .then(() => {
                next();
              })
              .catch(() => store.dispatch("loader/close"));
          } else {
            store.commit("error/SET_ERROR", {
              messageText: "dialog.error.pageNotFoundMessage",
            });
            next({ name: "Dashboard", params: { is404: true } });
          }
        },
      },
      {
        path: "projects",
        name: "Projects",
        component: () => import("../views/Dashboard.vue"),
        meta: {
          requiresAuth: true,
        },
        beforeEnter(to, from, next) {
          store
            .dispatch("project/retrieveProjectsByClientId").then(() => {
              return store.dispatch("folder/retrieveFoldersByClientId")
            })
            .then(() => {
              next();
            })
            .catch((isAuthorizationError) => {
              if (isAuthorizationError) store.dispatch("loader/close");
              else next();
            });
        },
      },
      {
        path: "support",
        name: "Support",
        component: () => import("../views/Support.vue"),
        meta: {
          requiresAuth: false,
        },
        beforeEnter(to, from, next) {
          next();
        },
      },
      {
        path: "project/:id",
        component: () => import("../views/Wizard.vue"),
        children: [
          {
            path: "audience",
            name: "Audience",
            component: () => import("../views/Audience.vue"),
            meta: {
              requiresAuth: true,
            },
            beforeEnter(to, from, next) {
              store.commit(
                "wizard/SET_ACTIVE_STEP",
                Utils.WIZARD_STEPS.AUDIENCE
              );
              const activeProjectId =
                store.getters["project/getActiveProjectId"];

              if (activeProjectId !== to.params.id)
                fetchProject(to.params.id, next, Utils.WIZARD_STEPS.AUDIENCE);
              else checkWizardStep(Utils.WIZARD_STEPS.AUDIENCE, next);
            },
          },
          {
            path: "stimulus",
            name: "Stimulus",
            component: () => import("../views/Stimulus.vue"),
            meta: {
              requiresAuth: true,
            },
            beforeEnter(to, from, next) {
              // TODO get data
              store.commit(
                "wizard/SET_ACTIVE_STEP",
                Utils.WIZARD_STEPS.STIMULUS
              );
              const activeProjectId =
                store.getters["project/getActiveProjectId"];

              if (activeProjectId !== to.params.id)
                fetchProject(to.params.id, next, Utils.WIZARD_STEPS.STIMULUS);
              else checkWizardStep(Utils.WIZARD_STEPS.STIMULUS, next);
            },
          },
          {
            path: "questions",
            name: "Questions",
            component: () => import("../views/Questions.vue"),
            meta: {
              requiresAuth: true,
            },
            beforeEnter(to, from, next) {
              // TODO get data
              store.commit(
                "wizard/SET_ACTIVE_STEP",
                Utils.WIZARD_STEPS.QUESTIONS
              );
              const activeProjectId =
                store.getters["project/getActiveProjectId"];

              if (activeProjectId !== to.params.id)
                fetchProject(to.params.id, next, Utils.WIZARD_STEPS.QUESTIONS);
              else checkWizardStep(Utils.WIZARD_STEPS.QUESTIONS, next);
            },
          },
          {
            path: "review",
            name: "Review",
            component: () => import("../views/Review.vue"),
            meta: {
              requiresAuth: true,
            },
            beforeEnter(to, from, next) {
              // TODO get data
              store.commit("wizard/SET_ACTIVE_STEP", Utils.WIZARD_STEPS.REVIEW);
              const activeProjectId =
                store.getters["project/getActiveProjectId"];

              if (activeProjectId !== to.params.id)
                fetchProject(to.params.id, next, Utils.WIZARD_STEPS.REVIEW);
              else checkWizardStep(Utils.WIZARD_STEPS.REVIEW, next);
            },
          },
          {
            path: "checkout",
            name: "Checkout",
            component: () => import("../views/Checkout.vue"),
            meta: {
              requiresAuth: true,
            },
            beforeEnter(to, from, next) {
              customCheckFunction(to, from, next);
            },
          },
        ],
      },
      {
        path: "new-project",
        name: "NewProject",
        component: () => import("../views/NewProject.vue"),
        meta: {
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters["auth/getUserType"] !== Utils.USER_TYPE.REGULAR) {
            store.commit("error/SET_ERROR", {
              messageText: "dialog.error.pageNotFoundMessage",
            });
            next({ name: "Dashboard", params: { is404: true } });
          } else next();
        },
      },
      {
        path: "success-payment",
        name: "SuccessPayment",
        component: () => import("../views/SuccessTransaction.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "*",
    name: "404",
    beforeEnter: (to, from, next) => {
      const supportedLocaleList = i18n.availableLocales;
      const splittedPath = to.path.split("/");
      if (
        splittedPath.length > 1 &&
        !supportedLocaleList.includes(splittedPath[1])
      ) {
        next({ path: `/${i18n.locale}${to.path}`, replace: true });
      } else {
        store.commit("error/SET_ERROR", {
          messageText: "dialog.error.pageNotFoundMessage",
        });
        next({ name: "Dashboard", params: { is404: true } });
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  store.commit("loader/SET_LOADER", {});
  if (!to.params.is404) {
    store.commit("error/RESET");
  }
  store.commit("confirm/RESET");
  store.commit("success/RESET");

  const isLoggedIn = store.getters["auth/isLoggedIn"];

  if (isLoggedIn && to.name === "Login") {
    next({ name: "Dashboard" });
  } else if (to.meta.requiresAuth) {
    if (isLoggedIn) {
      next();
    } else {
      next({ name: "Login", params: to.params, query: { redirect: to } });
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  setTimeout(() => {
    store.dispatch("loader/close");
  });
});

function fetchProject(projectId, next, currentStep) {
  store
    .dispatch("project/retrieveProjectByClientId", projectId)
    .then(() => {
      return store.dispatch("stimulus/retrieveProjectAssets");
    })
    .then(() => {
      checkWizardStep(currentStep, next);
    })
    .catch((isAuthorizationError) => {
      store.dispatch("loader/close");

      if (!isAuthorizationError) {
        store.commit("error/SET_ERROR", {
          messageText: "dialog.error.pageNotFoundMessage",
        });
        next({ name: "Dashboard", params: { is404: true } });
      }
    });
}

function checkWizardStep(currentStep, next) {
  const project = store.getters["project/getActiveProject"];
  if (project.step < currentStep || project.status !== "pending") {
    store.commit("error/SET_ERROR", {
      messageText: "dialog.error.pageNotFoundMessage",
    });
    next({ name: "Dashboard", params: { is404: true } });
  } else next();
}

function customCheckFunction(to, from, next) {
  const activeProjectId = store.getters["project/getActiveProjectId"];
  if (activeProjectId !== to.params.id)
    customFetchProject(to.params.id)
      .then(() => {
        const project = store.getters["project/getActiveProject"];
        if (
          project.step < Utils.WIZARD_STEPS.CHECKOUT ||
          project.status !== "pending"
        ) {
          store.commit("error/SET_ERROR", {
            messageText: "dialog.error.pageNotFoundMessage",
          });
          return Promise.reject(new Error(404));
        }
      })
      .then(() => {
        checkProject(to, from, next);
      })
      .catch(() => {
        next({ name: "Dashboard", params: { is404: true } });
      });
  else {
    const project = store.getters["project/getActiveProject"];
    if (
      project.step < Utils.WIZARD_STEPS.CHECKOUT ||
      project.status !== "pending"
    ) {
      store.commit("error/SET_ERROR", {
        messageText: "dialog.error.pageNotFoundMessage",
      });
      next({ name: "Dashboard", params: { is404: true } });
    } else {
      checkProject(to, from, next);
    }
  }
}

function customFetchProject(projectId) {
  return store
    .dispatch("project/retrieveProjectByClientId", projectId)
    .then(() => {
      return store.dispatch("stimulus/retrieveProjectAssets");
    });
}

function checkProject(to, from, next) {
  const isProjectValid = store.getters["wizard/isProjectValid"];
  if (!isProjectValid) {
    store.commit("error/SET_ERROR", {
      titleText: "page.checkout.error.incompleteProject.title",
      messageText: "page.checkout.error.incompleteProject.message",
    });
    next({
      name: "Review",
      params: {
        ...to.params,
        is404: true,
      },
      replace: true,
    });
  } else tryToRetrieveProjectFeasibility(to, from, next);
}

function tryToRetrieveProjectFeasibility(to, from, next) {
  store.commit("loader/SET_LOADER", {
    messageText: "dialog.loader.checkProject",
  });
  const STARTED = Date.now();
  const MAX_WAIT_SECONDS = 3 * 60;
  const INTERVAL_SECONDS = 15;
  const INTERVAL = setInterval(() => {
    if (Date.now() - STARTED > MAX_WAIT_SECONDS * 1000) {
      clearInterval(INTERVAL);
      feasibilityTimeExpired(to, from, next);
    } else {
      retrieveProjectFeasibility(INTERVAL, to, next);
    }
  }, INTERVAL_SECONDS * 1000);

  retrieveProjectFeasibility(INTERVAL, to, next);
}

function retrieveProjectFeasibility(interval, to, next) {
  store
    .dispatch("project/retrieveProjectFeasibility")
    .then((feasibilityList) => {
      const isFeasibilityValid = getFeasibilityStatus(feasibilityList);
      if (isFeasibilityValid) {
        clearInterval(interval);
        store.commit("wizard/SET_ACTIVE_STEP", Utils.WIZARD_STEPS.CHECKOUT);
        next();
      }
    })
    .catch(() => {
      clearInterval(interval);
      store.dispatch("loader/close");
      next({
        name: "Review",
        params: {
          ...to.params,
          is404: true,
        },
        replace: true,
      });
    });
}

function getFeasibilityStatus(feasibilityList) {
  if (feasibilityList.length === 0) {
    return false;
  }
  const READY_STATUS = "READY";
  const findInvalidIndex = feasibilityList.findIndex((feasibilityInfo) => {
    return (
      !feasibilityInfo.feasibility || feasibilityInfo.status !== READY_STATUS
    );
  });

  return findInvalidIndex === -1;
}

function feasibilityTimeExpired(to, from, next) {
  store.commit("error/SET_ERROR", {
    titleText: "dialog.error.projectFeasibility.title",
    messageText: "dialog.error.projectFeasibility.message",
  });

  store.dispatch("loader/close");

  next({
    name: "Review",
    params: {
      ...to.params,
      is404: true,
    },
    replace: true,
  });
}

function isAllowedAccountRoute() {
  const isMultiUser = store.getters["auth/isMultiUser"];
  const userType = store.getters["auth/getUserType"];
  const userSubType = store.getters["auth/getUserSubType"];

  if (userType === "custom" && isMultiUser) {
    // allow only admins to access account route
    if (userSubType === "admin") {
      return true;
    } else {
      return false;
    }
  }
  return true;
}

function isAllowedBranchesRoute() {
  const isMultiUser = store.getters["auth/isMultiUser"];
  const userType = store.getters["auth/getUserType"];
  const userSubType = store.getters["auth/getUserSubType"];

  // only custom + multiuser clients can access route
  if (userType === "custom" && isMultiUser) {
    // allow only admins to access branches route
    if (userSubType === "admin") {
      return true;
    } else {
      return false;
    }
  }

  // return false for all other client types
  return false;
}

function isAllowedUsersRoute() {
  const isMultiUser = store.getters["auth/isMultiUser"];
  const userType = store.getters["auth/getUserType"];
  const userSubType = store.getters["auth/getUserSubType"];

  // only custom + multiuser clients can access route
  if (userType === "custom" && isMultiUser) {
    // allow everyone except researchers to access route
    if (
      userSubType === "admin" ||
      userSubType === "branch" ||
      userSubType === "collaborator"
    ) {
      return true;
    } else {
      return false;
    }
  }

  // return false for all other client types
  return false;
}

function isAllowedBillingRoute() {
  const isMultiUser = store.getters["auth/isMultiUser"];
  const userType = store.getters["auth/getUserType"];
  const userSubType = store.getters["auth/getUserSubType"];

  // only custom clients can access route
  if (userType === "custom") {
    // prevent access to branch, collaborator, researcher subType
    if (
      isMultiUser &&
      (userSubType === "branch" ||
        userSubType === "collaborator" ||
        userSubType === "researcher")
    ) {
      return false;
    }
    // all other custom clients can access route
    return true;
  }
  // return false for all other client types
  return false;
}

function isAllowedUsageRoute() {
  const isMultiUser = store.getters["auth/isMultiUser"];
  const userType = store.getters["auth/getUserType"];
  const userSubType = store.getters["auth/getUserSubType"];

  // only custom clients can access route
  if (userType === "custom") {
    // prevent access to branch, collaborator, researcher subType
    if (
      isMultiUser &&
      (userSubType === "branch" ||
        userSubType === "collaborator" ||
        userSubType === "researcher")
    ) {
      return false;
    }
    // all other custom clients can access route
    return true;
  }
  // return false for all other client types
  return false;
}
export default router;
